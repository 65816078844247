import React from "react";
import MainTemplate from "templates/MainTemplate";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  margin-top: 40px;
`;

const InnerWrapper = styled.div`
  max-width: 1100px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 60px;
  img {
    margin: 10px 15px;
  }
`;

const StyledTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  position: relative;
  display: inline-block;
  margin: 10px;
  &::after {
    content: "";
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: -10px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gold};
  }
`;

const Position = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  article {
    /* margin-left: 30px; */
  }
`;

const Prywatnosc = () => (
  <MainTemplate>
    <Helmet>
      <title>Polityka prywatności i cookies - Adwokat Adrianna Firlej-Koniecka</title>
    </Helmet>
    <Background>
      <InnerWrapper>
        <StyledTitle>Polityka prywatności i cookies</StyledTitle>
        <Position>
          <article>
            Poniższa polityka prywatności i ciasteczek opisuje zasady
            postępowania z danymi osobowymi oraz wykorzystywania ciasteczek i
            innych technologii w ramach strony internetowej
            https://adwokatfirlej.pl Administratorem strony jest Adrianna
            Firlej-Koniecka, prowadząca działalność gospodarczą Kancelaria Adwokacka
            Adwokat Adrianna Firlej-Koniecka ul.Orla 3/1, 20-022 Lublin, wpisana do
            Centralnej Ewidencji i Informacji o Działalności Gospodarczej,
            posługujący się numerem NIP 7123426326. Możliwy kontakt pod adresem:
            firlej.adwokat@gmail.com
            <h3>§ 1 Definicje</h3>
            <ul>
              <li>
                Serwis – serwis internetowy działający pod adresem
                adwokatfirlej.pl
              </li>
              <li>
                Serwis zewnętrzny - serwis internetowe partnerów, usługodawców
                lub usługobiorców Administratora
              </li>
              <li>
                Administrator - firma Kancelaria Adwokacka Adwokat Adrianna
                Firlej-Koniecka, prowadząca działalność pod adresem: ul. Orla 3/1, 20-022
                Lublin, o nadanym numerze identyfikacji podatkowej (NIP):
                7123426326, o nadanym numerze REGON: 520371774, świadcząca
                usługi drogą elektroniczną za pośrednictwem Serwisu oraz
                przechowująca i uzyskująca dostęp do informacji w urządzeniach
                Użytkownika
              </li>
              <li>
                Użytkownik – osoba fizyczna, dla której Administrator świadczy
                usługi drogą elektroniczna za pośrednictwem Serwisu.
              </li>
              <li>
                Urządzenie – elektroniczne urządzenie wraz z oprogramowaniem, za
                pośrednictwem, którego Użytkownik uzyskuje dostęp do Serwisu
              </li>
              <li>
                Cookies (ciasteczka) – dane tekstowe gromadzone w formie plików
                zamieszczanych na Urządzeniu Użytkownika
              </li>
            </ul>
            <h3>§ 2 Rodzaje cookies</h3>
            <ul>
              <li>
                Cookies wewnętrzne - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przes system teleinformatyczny Serwisu
              </li>
              <li>
                Cookies zewnętrzne - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów
                zewnętrznych
              </li>
              <li>
                Cookies sesyjne - pliki zamieszczane i odczytywane z Urządzenia
                Użytkownika przez Serwis podczas jednej sesji danego Urządzenia.
                Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.
              </li>
              <li>
                Cookies trwałe - pliki zamieszczane i odczytywane z Urządzenia
                Użytkownika przez Serwis do momentu ich ręcznego usunięcia.
                Pliki nie są usuwane automatycznie po zakończeniu sesji
                Urządzenia chyba że konfiguracja Urządzenia Użytkownika jest
                ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji
                Urządzenia.
              </li>
            </ul>
            <h3>§ 3 Bezpieczeństwo</h3>
            <ul>
              <li>
                Mechanizmy składowania i odczytu - Mechanizmy składowania i
                odczytu Cookies nie pozwalają na pobierania jakichkolwiek danych
                osobowych ani żadnych informacji poufnych z Urządzenia
                Użytkownika. Przeniesienie na Urządzenie Użytkownika wirusów,
                koni trojańskich oraz innych robaków jest praktynie niemożliwe.
              </li>
              <li>
                Cookie wewnętrzne - zastosowane przez Administratora Cookie
                wewnętrzne są bezpieczne dla Urządzeń Użytkowników
              </li>
              <li>
                Cookie zewnętrzne - za bezpieczeństwo plików Cookie pochodzących
                od partnerów Serwisu Administrator nie ponosi odpowiedzialności.
                Lista partnerów zamieszczona jest w dalszej części Polityki
                Cookie.
              </li>
            </ul>
            <h3>§ 4 Cele, do których wykorzystywane są pliki cookie</h3>
            <ul>
              <li>
                Usprawnienie i ułatwienie dostępu do Serwisu - Administrator
                może przechowywać w plikach Cookie informacje o prefernecjach i
                ustawieniach użytkownika dotyczących Serwisu aby usprawnić,
                polepszyć i przyśpieszyć świadczenie usług w ramach Serwisu.
              </li>
            </ul>
            <h3>§ 5 Serwisy zewnętrzne</h3>
            <ul>
              <li>
                Administrator nie współpracuje z serwisami zewnętrznymi i Serwis
                nie umieszcza ani nie korzysta z żadnych plików zewnętrznych
                plików Cookie.
              </li>
            </ul>
            <h3>
              § 6 Możliwość określania warunków przechowywania i uzyskiwania
              dostępu na urządzeniach użytkownika przez serwis
            </h3>
            <ul>
              <li>
                Użytkownik może w dowolnym momencie, samodzielnie zmienić
                ustawienia dotyczące zapisywania, usuwania oraz dostępu do
                danych zapisanych plików Cookies
              </li>
              <li>
                Informacje o sposobie wyłączenia plików Cookie w
                najpopularniejszych przeglądarkach komputerowych i urządzeń
                mobilnych dostępna są na stronie: https://jakwylaczyccookie.pl/
              </li>
              <li>
                Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do
                tej pory pliki Cookie korzystając z narzędzi Urządzenia
                Użytkownika za pośrednictwem którego Użytkowanik korzysta z
                usług Serwisu.
              </li>
            </ul>
            <h3>§ 7 Wyłączenie odpowiedzialności</h3>
            <ul>
              <li>
                Administrator stosuje wszelkie możliwe środki w celu zapewnienia
                bezpieczeństwa danych umieszczanych w plikach Cookie. Należy
                jednak zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych
                zależy od obu stron, w tym działalności Użytkownika oraz satnu
                zabezpieczeń urządzenia z którego korzysta.
              </li>
              <li>
                Administrator nie bierze odpowiedzialności za przechwycenie
                danych zawartych w plikach Cookie, podszycie się pod sesję
                Użytkownika lub ich usunięcie, na skutek świadomej lub
                nieświadomej działalność Użytkownika, wirusów, koni trojańskich
                i innego oprogramowania szpiegującego, którymi może być
                zainfekowane Urządzenie Użytkownika.
              </li>
              <li>
                Użytkownicy w celu zabezpieczenia się przed wskazanymi w punkcie
                poprzednim zagrożeniami powinni stosować się do zasad
                bezpiecznego korzystania z sieci internet.
              </li>
              <li>
                Usługi świadczone przez podmioty trzecie są poza kontrolą
                Administratora. Podmioty te mogą w każdej chwili zmienić swoje
                warunki świadczenia usług, cel oraz wykorzystanie plików cookie.
                Administrator nie odpowiada na tyle na ile pozwala na to prawo
                za działanie plików cookies używanych przez serwisy partnerskie.
                Użytkownicy w każdej chwili mogą samodzielnie zarządzać
                zezwoleniami i ustawieniami plików cookie dla każedej dowolnej
                witryny.
              </li>
            </ul>
            <h3>§ 8 Wymagania serwisu</h3>
            <ul>
              <li>
                Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu
                Użytkownika może spowodować nieprawidłowe działanie niektórych
                funkcji Serwisu.
              </li>
              <li>
                Administrator nie ponosi żadnej odpowiedzialności za
                nieprawidłowo działające funkcje Serwisu w przypadku gdy
                Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania
                i odczytu plików Cookie.
              </li>
            </ul>
            <h3>§ 9 Zmiany w polityce cookie</h3>
            <ul>
              <li>
                Administrator zastrzega sobie prawo do dowolnej zmiany
                niniejszej Polityki Cookie bez konieczności informowania o tym
                użytkowników.
              </li>
              <li>
                Wprowadzone zmiany w Polityce Cookie zawsze będą publikowane na
                tej stronie.
              </li>
              <li>
                Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki
                Cookie.
              </li>
            </ul>
          </article>
        </Position>
      </InnerWrapper>
    </Background>
  </MainTemplate>
);

export default Prywatnosc;
